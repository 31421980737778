<template>
  <b-container fluid="full">
    <h1 class="w-full">
      <template v-if="product.type == 'Spray'">
        <span>{{ $t("titlePrefix") }} </span>
        <span>
          {{ product.recipe.num }}
        </span>
        <span v-if="hasInput"> {{ $t("titleAppendix") }}</span>
      </template>
      <template v-else-if="product.details.intro">
        {{ $t("titlePrefixProduct") }}{{ product.details.name }}
      </template>
      <template v-else>
        <div>
          {{ product.num }}
        </div>
      </template>
    </h1>
    <p class="f-p2">
      {{ product.details.intro }}
    </p>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailHeader",
  props: ["product", "hasInput"],
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

p {
  font-weight: 400;
  font-family: var(--text-montserrat);
}
</style>
