<template>
  <b-container fluid="xxl" class="my-5">
    <!-- Infotext & Erfahrungsberichte-->
    <b-row>
      <b-col cols="12" md="6" class="my-4">
        <h2>{{ $t("aboutText.heading") }}</h2>
        <div>
          {{ $t("aboutText.text") }}
        </div>
      </b-col>
      <b-col cols="12" md="6" class="my-4">
        <h2>{{ $t("generalReviews.moreBtn") }}</h2>
        <b-row class="my-3">
          <b-col>
            <b-img src="@/assets/mandy.jpg" class="report-img" />
          </b-col>
          <b-col>
            <div class="mb-2">
              <span class="heading f-h3 py-2"
                >5<span style="font-weight: 400"
                  >/<span class="f-p4">5</span>
                </span>
              </span>
              <span class="nps-stars">
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
              </span>
            </div>
            <h3>{{ $t("generalReviews.mandy.nameCity") }}</h3>
            <p>
              {{ $t("generalReviews.mandy.review") }}
            </p>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col>
            <b-img src="@/assets/katrin.jpg" class="report-img" />
          </b-col>
          <b-col>
            <div class="mb-2">
              <span class="heading f-h3 py-2"
                >5<span style="font-weight: 400"
                  >/<span class="f-p4">5</span>
                </span>
              </span>
              <span class="nps-stars">
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
                <BIconStarFill scale="1.2" />
              </span>
            </div>
            <h3>{{ $t("generalReviews.katrin.nameCity") }}</h3>
            <p>
              {{ $t("generalReviews.katrin.review") }}
            </p>
          </b-col>
        </b-row>
        <b-button
          href="https://www.trustedshops.de/bewertung/info_XBE7E097579748F9F559462E7D0DF5217.html"
          target="_blank"
          variant="light"
          class="f-p2 px-5 w-100 text-uppercase heading text-center"
        >
          {{ $t("buttonShowAllReviews") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BIconStarFill } from "bootstrap-vue";

export default {
  name: "ProductDetailZimplyGeneral",
  components: {
    BIconStarFill,
  },
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped></style>
