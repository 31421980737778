var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionary",class:_vm.curr_step ? _vm.curr_step.step.replace(/#/g, '') : ''},[_c('transition',{attrs:{"name":"t-ud"},on:{"before-enter":_vm.beforeTransition,"before-leave":_vm.beforeTransition,"after-enter":_vm.afterTransitionEnter,"after-leave":_vm.afterTransitionLeave}},[[(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'start'
        )?_c('QuestionStart',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"running":_vm.running},on:{"next_without":_vm.next_without}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'option'
        )?_c('QuestionOption',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'option-multiselect'
        )?_c('QuestionOptionMultiselect',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'input'
        )?_c('QuestionInput',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"error":_vm.error,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'confirm'
        )?_c('QuestionConfirm',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"error":_vm.error,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'range-slider'
        )?_c('QuestionRangeSlider',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"error":_vm.error,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(
          _vm.curr_step &&
          _vm.curr_step.question &&
          _vm.curr_step.question.type == 'circle-slider'
        )?_c('QuestionCircleSlider',{key:_vm.curr_step.question.id,attrs:{"step":_vm.curr_step,"answers":_vm.answers,"error":_vm.error,"running":_vm.running},on:{"answer_and_next":_vm.answer_and_next}}):_vm._e(),_vm._v(" "),(_vm.curr_step && _vm.curr_step.step == '##SHOW_MIXTURE##')?_c('ProductDetail',{attrs:{"product":_vm.product,"firstname":_vm.product.recipe.input.Vorname ?? null,"hasInput":true}}):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }