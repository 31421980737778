<template>
  <div>
    <div class="navbar">
      <div class="container-fluid align-items-center">
        <div class="col p-0">
          <b-button
            v-show="startButtonShow"
            to="/"
            :title="$t('startButtonTooltip')"
            variant="link"
          >
            <BIconChevronBarLeft scale="1.6" />
          </b-button>
          <b-dropdown variant="link" class="language-switcher">
            <template #button-content>
              <b-img
                :src="languages[$i18next.resolvedLanguage].img"
                width="18"
                height="12"
              />
              <span class="display-desktop">{{
                languages[$i18next.resolvedLanguage].name
              }}</span>
            </template>
            <b-dropdown-item
              v-for="lng in remainingLanguages"
              :key="lng"
              @click="changeLanguage(lng)"
            >
              <b-img :src="languages[lng].img" width="18" height="12" />
              {{ languages[lng].name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="col-5 text-center">
          <b-link :href="$t('homeURL')" class="logo">
            <b-img src="@/assets/logo-quer.png" class="w-100"></b-img>
          </b-link>
        </div>
        <div class="nav-buttons col text-right p-0">
          <b-button
            variant="link"
            :title="
              $user.loggedIn
                ? $user.firstname + ' ' + $user.lastname
                : $t('userButtonTooltip')
            "
            @click="handleAccountClick()"
          >
            <BIconPersonFill v-if="$user.loggedIn" scale="1.6" />
            <BIconPerson v-else scale="1.6" />
          </b-button>
          <b-button
            class="cart-button"
            to="/bestellung"
            :title="$t('cartButtonTooltip')"
            variant="link"
          >
            <BIconBag scale="1.3" />
            <b-badge v-if="cartCount > 0" variant="danger">{{
              cartCount
            }}</b-badge>
          </b-button>
          <b-button
            class="display-mobile"
            variant="link"
            v-b-toggle.sidebar-menu
          >
            <BIconList scale="1.3" />
          </b-button>
        </div>
      </div>
      <ModalLogin />
    </div>
    <div class="menu display-desktop">
      <b-nav align="center">
        <b-nav-item-dropdown :text="$t('menu.products.group')">
          <b-dropdown-item :href="$t('menu.products.bestseller.link')">
            {{ $t("menu.products.bestseller.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.products.productassistant.link')">
            {{ $t("menu.products.productassistant.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.products.standard.link')">
            {{ $t("menu.products.standard.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.products.essences.link')">
            {{ $t("menu.products.essences.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.products.individual.link')">
            {{ $t("menu.products.individual.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.products.animal.link')">
            {{ $t("menu.products.animal.label") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :href="$t('menu.plants.link')">
          {{ $t("menu.plants.label") }}
        </b-nav-item>
        <b-nav-item-dropdown :text="$t('menu.webinare.group')">
          <b-dropdown-item :href="$t('menu.webinare.link')">
            {{ $t("menu.webinare.label") }}
          </b-dropdown-item>
          <b-dropdown-item :href="$t('menu.speakers.link')">
            {{ $t("menu.speakers.label") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :href="$t('menu.blog.link')">
          {{ $t("menu.blog.label") }}
        </b-nav-item>
        <b-nav-item :href="$t('menu.about.link')">
          {{ $t("menu.about.label") }}
        </b-nav-item>
        <b-nav-item :href="$t('menu.faq.link')">
          {{ $t("menu.faq.label") }}
        </b-nav-item>
      </b-nav>
    </div>
    <b-sidebar id="sidebar-menu" class="menu" shadow right backdrop>
      <b-nav vertical class="text-center">
        <div class="menu-group nav-link">
          {{ $t("menu.products.group") }}
          <b-nav-item :href="$t('menu.products.bestseller.link')">
            {{ $t("menu.products.bestseller.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.products.productassistant.link')">
            {{ $t("menu.products.productassistant.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.products.standard.link')">
            {{ $t("menu.products.standard.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.products.essences.link')">
            {{ $t("menu.products.essences.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.products.individual.link')">
            {{ $t("menu.products.individual.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.products.animal.link')">
            {{ $t("menu.products.animal.label") }}
          </b-nav-item>
        </div>
        <b-nav-item :href="$t('menu.plants.link')">
          {{ $t("menu.plants.label") }}
        </b-nav-item>
        <div class="menu-group nav-link">
          {{ $t("menu.webinare.group") }}
          <b-nav-item :href="$t('menu.webinare.link')">
            {{ $t("menu.webinare.label") }}
          </b-nav-item>
          <b-nav-item :href="$t('menu.speakers.link')">
            {{ $t("menu.speakers.label") }}
          </b-nav-item>
        </div>
        <b-nav-item :href="$t('menu.blog.link')">
          {{ $t("menu.blog.label") }}
        </b-nav-item>
        <b-nav-item :href="$t('menu.about.link')">
          {{ $t("menu.about.label") }}
        </b-nav-item>
        <b-nav-item :href="$t('menu.faq.link')">
          {{ $t("menu.faq.label") }}
        </b-nav-item>
      </b-nav>
    </b-sidebar>
    <div class="progress-row bg-white">
      <div class="row w-100 align-items-center mb-4 mt-2 mx-0">
        <div class="nav-buttons col">
          <b-button
            v-if="progress_max"
            href="#"
            @click.prevent="go_back()"
            :title="$t('backButtonTooltip')"
            variant="link"
            class="mb-3 mb-md-0"
          >
            <BIconChevronLeft scale="1.6" />
            <span class="back-button-text">{{ $t("backButtonText") }}</span>
          </b-button>
        </div>
        <div class="progress-wrapper col-md-8 col-12">
          <b-progress
            v-if="progress_max"
            height="4px"
            :value="progress_curr"
            :max="progress_max"
          ></b-progress>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalLogin from "@/components/ModalLogin.vue";

import {
  BIconBag,
  BIconChevronBarLeft,
  BIconChevronLeft,
  BIconPerson,
  BIconPersonFill,
  BIconList,
} from "bootstrap-vue";

export default {
  name: "TheNavbar",
  components: {
    BIconChevronBarLeft,
    BIconChevronLeft,
    BIconPerson,
    BIconPersonFill,
    ModalLogin,
    BIconBag,
    BIconList,
  },
  i18nOptions: { keyPrefix: "navbar" },
  data: function () {
    return {
      progress_curr: null,
      progress_max: null,
      loginOpen: false,
      languages: {
        en: { name: "EN", img: require("@/assets/en.svg") },
        de: { name: "DE", img: require("@/assets/de.svg") },
        es: { name: "ES", img: require("@/assets/es.svg") },
        fr: { name: "FR", img: require("@/assets/fr.svg") },
        it: { name: "IT", img: require("@/assets/it.svg") },
      },
      startButtonShow: false,
      startButtonTimer: null,
    };
  },
  computed: {
    cartCount: function () {
      let cnt = 0;
      this.$cart.forEach((position) => {
        cnt += position.count;
      });
      return cnt;
    },
    remainingLanguages: function () {
      return Object.keys(this.languages).filter(
        (language) => language != this.$i18next.resolvedLanguage
      );
    },
  },
  created: function () {
    this.$EventBus.$on("update_progress", this.updateProgress);
  },
  destroyed: function () {
    this.$EventBus.$off("update_progress", this.updateProgress);
  },
  watch: {
    "$route.path": function () {
      this.timeStartButtonShow();
    },
    progress_max: function () {
      this.timeStartButtonShow();
    },
  },
  methods: {
    updateProgress: function (curr, max) {
      this.progress_curr = curr;
      this.progress_max = max;
    },
    go_back: function () {
      this.$EventBus.$emit("go_back");
    },
    handleAccountClick: function () {
      if (this.$user.loggedIn) {
        if (this.$route.path != "/user/account") {
          this.$router.push("/user/account");
        }
        return;
      }
      this.$EventBus.$emit("open_login");
    },
    timeStartButtonShow: function () {
      if (this.startButtonTimer) {
        clearTimeout(this.startButtonTimer);
      }
      this.startButtonTimer = setTimeout(() => {
        this.startButtonShow =
          this.$route.path.length > 4 &&
          (!this.progress_max || this.progress_max == this.progress_curr);
      }, 200);
    },
    changeLanguage: function (lng) {
      this.$i18next.changeLanguage(lng);
      this.$router.replace({ params: { lang: lng } });
    },
  },
};
</script>
<style lang="scss">
.language-switcher {
  button {
    min-width: unset !important;
  }
  .dropdown-menu {
    min-width: unset;
    border: unset;
    padding: 0 0 0 7px;
    top: -5px !important;
  }
  @media only screen and (max-width: 600px) {
    .dropdown-menu {
      padding: 0;
    }
  }
  .dropdown-item {
    padding: 1px 20px 1px 6px;
  }
  img {
    border-radius: unset;
    vertical-align: unset;
  }
}
</style>
<style>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1030;
  min-height: 70px;
  padding: 0;
  background-color: var(--white);
  user-select: none;
}
@media only screen and (max-width: 576px) {
  .navbar .container-fluid {
    padding-left: 2px;
    padding-right: 2px;
  }
}
.navbar .logo img {
  max-width: 220px;
}
.btn-link {
  color: var(--zimply-black);
}
.btn-link:hover {
  color: var(--zimply-light-green);
  text-decoration: none;
}
.back-button-text {
  font-family: var(--text-nunito);
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 5px;
}

.cart-button {
  position: relative;
}
.cart-button .badge {
  position: absolute;
  font-size: 0.5rem;
  left: 27px;
  top: 5px;
  background-color: var(--red-dark);
}
.progress {
  background-color: var(--zimply-beige);
}
.progress-bar {
  background-color: var(--zimply-dark-green);
  border-radius: 0.25rem;
}

@media only screen and (min-width: 600px) {
  .navbar .logo {
    position: relative;
    left: 20px;
  }
  .nav-buttons.left {
    left: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .navbar .logo {
    margin: auto;
  }
  .nav-buttons.left {
    left: 6px;
  }
  .btn-link {
    font-size: 15px;
    padding: 5px;
  }
  .cart-button .badge {
    left: 18px;
    top: 5px;
  }
}
#sidebar-menu {
  background-color: var(--zimply-dark-green) !important;
}
.menu .nav-link {
  font-family: var(--text-montserrat);
  font-weight: 700;
  color: var(--zimply-black);
}
.menu .menu-group .nav-link {
  font-weight: 400;
}
#sidebar-menu .nav-link {
  color: var(--zimply-beige);
}
.menu .nav-link:hover {
  color: var(--zimply-light-green);
}
</style>
