<template>
  <b-container fluid="xxl" class="my-5">
    <!-- Beirat -->
    <b-row>
      <b-col>
        <h2 class="mb-1 text-center">{{ $t("advisor.heading") }}</h2>
        <div class="f-h3 heading fw-normal text-center">
          {{ $t("advisor.subheading") }}
        </div>
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col cols="12" class="trust-badge">
        <b-img src="@/assets/medical-beirat-black.png" />
      </b-col>
      <b-col cols="12">
        <div class="position-absolute prev-btn">
          <b-button @click="prev()" href="#" variant="link">
            <BIconChevronLeft />
          </b-button>
        </div>
        <VueSlickCarousel
          v-bind="settings"
          class="advisor-slider"
          ref="advisorCarousel"
        >
          <div class="advisor-card-wrapper">
            <div class="advisor-card">
              <b-img src="@/assets/vicariesmann.png" class="w-100" />
              <h3 class="mt-3 mx-3 mb-0">
                <i18next :translation="$t('advisor.vicariesmann.name')">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </h3>
              <div class="p-3">{{ $t("advisor.vicariesmann.desc") }}</div>
            </div>
          </div>
          <div class="advisor-card-wrapper">
            <div class="advisor-card">
              <b-img src="@/assets/heidecker.png" class="w-100" />
              <h3 class="mt-3 mx-3 mb-0">
                <i18next :translation="$t('advisor.heidecker.name')">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </h3>
              <div class="p-3">
                {{ $t("advisor.heidecker.desc") }}
              </div>
            </div>
          </div>
          <div class="advisor-card-wrapper">
            <div class="advisor-card">
              <b-img src="@/assets/hp.webp" class="w-100" />
              <h3 class="mt-3 mx-3 mb-0">
                <i18next :translation="$t('advisor.eisenkolb.name')">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </h3>
              <div class="p-3">{{ $t("advisor.eisenkolb.desc") }}</div>
            </div>
          </div>
          <div class="advisor-card-wrapper">
            <div class="advisor-card">
              <b-img src="@/assets/wehner.png" class="w-100" />
              <h3 class="mt-3 mx-3 mb-0">
                <i18next :translation="$t('advisor.wehner.name')">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </h3>
              <div class="p-3">
                {{ $t("advisor.wehner.desc") }}
              </div>
            </div>
          </div>
          <div class="advisor-card-wrapper"></div>
        </VueSlickCarousel>
        <div class="position-absolute next-btn">
          <b-button @click="next()" href="#" variant="link">
            <BIconChevronRight />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BIconChevronLeft, BIconChevronRight } from "bootstrap-vue";

import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ProductDetailAdvisors",
  i18nOptions: { keyPrefix: "productDetail" },
  components: {
    BIconChevronLeft,
    BIconChevronRight,
    VueSlickCarousel,
  },
  data: function () {
    return {
      settings: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        slidesToShow: 4,
        infinite: false,
        speed: 500,
        swipe: false,
        dots: false,
        arrows: false,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              initialSlide: 1,
              centerPadding: "10px",
              swipe: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    next() {
      this.$refs.advisorCarousel.next();
    },
    prev() {
      this.$refs.advisorCarousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.trust-badge {
  margin-block: 1rem;

  img {
    width: 15%;

    @media only screen and (max-width: 768px) {
      width: 35%;
    }
  }
}

.advisor-card-wrapper {
  height: 100%;
  padding: 0 0.5rem;

  .advisor-card {
    background-color: var(--zimply-brown);
    border-radius: 10px;
    height: 100%;
    hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 0.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-left {
    padding-right: 5px;
  }

  .mobile-right {
    padding-left: 5px;
  }
}

.fw-normal {
  font-weight: 400;
}

.prev-btn,
.next-btn {
  z-index: 10;
  top: -40px;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.prev-btn {
  right: 35px;
}
.next-btn {
  right: 5px;
}
</style>

<style lang="scss">
.advisor-slider {
  & > div {
    padding-left: 0 !important;
  }
}
</style>
