import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/storefront-ui";
import "./plugins/helpers";
import "./plugins/checkout";
import i18n from "./plugins/i18n";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import "./fonts/fonts.css";

Vue.use(VueMeta);

Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();

Vue.prototype.$ZNC_BACKEND_BASE = "";
Vue.prototype.$ZNC_ENV = "PROD";
let hostname = window.location.hostname.toLowerCase();
if (hostname.includes(".dev.")) {
  Vue.prototype.$ZNC_ENV = "DEV";
} else if (hostname.includes(".stg.") || hostname.includes(".stage")) {
  Vue.prototype.$ZNC_ENV = "STAGE";
} else if (
  hostname.includes("localhost") ||
  hostname.startsWith("192.168.") ||
  hostname.includes("configurator-ui")
) {
  Vue.prototype.$ZNC_ENV = "LOCAL DEV";
}

const user = Vue.observable({ data: { loggedIn: false } });
Object.defineProperty(Vue.prototype, "$user", {
  get() {
    return user.data;
  },
  set(value) {
    user.data = value;
  },
});

const pharmacy = Vue.observable({ data: { available: false } });
Object.defineProperty(Vue.prototype, "$pharmacy", {
  get() {
    return pharmacy.data;
  },
  set(value) {
    pharmacy.data = value;
  },
});

const urlParams = new URLSearchParams(window.location.search);
const forcePharmacy = urlParams.get("za");

Vue.prototype.$znc_stats = Vue.observable({
  configs: "50.000",
  ts_stars: 4.89,
});
Vue.prototype.$sessionAvailable = new Promise((resolve) => {
  fetch(Vue.prototype.$ZNC_BACKEND_BASE + "/business/v1/create-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      /*global ZNC_KEY*/
      key: ZNC_KEY,
      pharmacy: forcePharmacy,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (
        result.error &&
        result.error == 103 &&
        !window.location.href.includes("r=t20")
      ) {
        let ts = new Date()
          .toISOString()
          .replaceAll("-", "")
          .replaceAll(":", "")
          .replaceAll(".", "");
        let url = window.location.href;
        if (url.includes("?")) {
          url = url + "&r=t" + ts;
        } else {
          url = url + "?r=t" + ts;
        }
        window.location.href = url;
      }

      Vue.prototype.$znc_stats.configs = result.znc_stats.configs;
      Vue.prototype.$znc_stats.ts_stars = result.znc_stats.ts_stars;
      Vue.prototype.$pharmacy = result.pharmacy;
      Vue.prototype.$pharmacy.available = true;
      Vue.prototype.$EventBus.$emit(
        "track_abtest",
        "Apotheken_2023-10",
        result.pharmacy.address.city.replace(" ", "")
      );
      Vue.prototype.$stripe_pk = result.payment.stripe_pk;
      Vue.prototype.$paypal_cid = result.payment.paypal_cid;

      if (
        document.cookie
          .split(";")
          .some((item) => item.trim().startsWith("znc_utoken="))
      ) {
        Vue.prototype.$userChecked = new Promise((resolve) => {
          fetch(Vue.prototype.$ZNC_BACKEND_BASE + "/business/v1/user/check", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              if (result.success) {
                Vue.prototype.$user = result.user;
                Vue.prototype.$user.loggedIn = true;
              }
              resolve();
            });
        });
      }

      resolve();
    });
});

const cart = Vue.observable({ data: [] });
Object.defineProperty(Vue.prototype, "$cart", {
  get() {
    return cart.data;
  },
  set(value) {
    cart.data = value;
  },
});

const order_info = Vue.observable({ data: {} });
Object.defineProperty(Vue.prototype, "$order_info", {
  get() {
    return order_info.data;
  },
  set(value) {
    order_info.data = value;
  },
});

const chat = Vue.observable({ data: { available: false } });
Object.defineProperty(Vue.prototype, "$chat", {
  get() {
    return chat.data;
  },
  set(value) {
    chat.data = value;
  },
});

Vue.prototype.$search = Vue.observable({ string: null, result: null });

Vue.prototype.$isMobile = window.innerWidth < 768;

Vue.prototype.$zmode = urlParams.get("zm");

i18n(() => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
