<template>
  <b-container fluid="xxl" class="my-5">
    <!-- Reviews -->
    <b-row>
      <b-col>
        <h2>{{ $t("reviews.heading") }}</h2>
      </b-col>
    </b-row>
    <b-row class="mb-2" align-v="center">
      <b-col class="heading f-h2">
        {{ this.formatNumber(this.$znc_stats.ts_stars) }}
        <span style="font-weight: 400">/<span class="f-p2">5</span> </span>
      </b-col>
      <b-col class="nps-stars text-end">
        <BIconStarFill
          scale="1"
          v-for="(fullStar, index) in calculateStars(
            this.$znc_stats.ts_stars
          )[0]"
          :key="'s1-' + index"
        />
        <BIconStarHalf
          scale="1"
          v-for="(fullStar, index) in calculateStars(
            this.$znc_stats.ts_stars
          )[1]"
          :key="'s2-' + index"
        />
        <BIconStar
          scale="1"
          v-for="(fullStar, index) in calculateStars(
            this.$znc_stats.ts_stars
          )[2]"
          :key="'s3-' + index"
        />
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="stretch">
      <b-col cols="12">
        <div
          :class="reviews.length < 1 ? 'd-none' : 'position-absolute prev-btn'"
        >
          <b-button @click="prev()" href="#" variant="link">
            <BIconChevronLeft />
          </b-button>
        </div>
        <VueSlickCarousel
          v-bind="settings"
          ref="reviewCarousel"
          class="review-slider"
        >
          <div
            v-for="(review, index) in reviews"
            :key="index"
            class="px-2 h-100"
          >
            <div class="review-card">
              <h3 class="review-name">{{ review.name }}</h3>
              <div>
                <BIconStarFill
                  v-for="(fullStar, i2) in calculateStars(review.stars)[0]"
                  :key="'rs1-' + index + '-' + i2"
                />
                <BIconStarHalf
                  v-for="(fullStar, i2) in calculateStars(review.stars)[1]"
                  :key="'rs2-' + index + '-' + i2"
                />
                <BIconStar
                  v-for="(fullStar, i2) in calculateStars(review.stars)[2]"
                  :key="'rs3-' + index + '-' + i2"
                />
              </div>
              <div class="f-p4 mb-2">
                {{ review.headline }}
              </div>
              <div>
                {{ review.text }}
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div
          :class="reviews.length < 1 ? 'd-none' : 'position-absolute next-btn'"
        >
          <b-button @click="next()" href="#" variant="link">
            <BIconChevronRight />
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" lg="5" class="mt-3">
        <b-button
          href="https://www.trustedshops.de/bewertung/info_XBE7E097579748F9F559462E7D0DF5217.html"
          target="_blank"
          variant="light"
          class="f-p2 px-5 w-100 text-uppercase heading text-center"
        >
          {{ $t("buttonShowAllReviews") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BIconStarFill,
  BIconStarHalf,
  BIconStar,
  BIconChevronLeft,
  BIconChevronRight,
} from "bootstrap-vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
//import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ProductDetailCustomerReviews",
  components: {
    BIconStarFill,
    BIconStarHalf,
    BIconStar,
    BIconChevronLeft,
    BIconChevronRight,
    VueSlickCarousel,
  },
  props: ["reviews"],
  i18nOptions: { keyPrefix: "productDetail" },
  methods: {
    calculateStars: function (stars) {
      var fullStars = parseInt(stars);
      var halfStars = 0;
      if (parseFloat(stars) % 1 >= 0.5) halfStars = 1;
      return [fullStars, halfStars, 5 - halfStars - fullStars];
    },
    next() {
      this.$refs.reviewCarousel.next();
    },
    prev() {
      this.$refs.reviewCarousel.prev();
    },
  },
  data: function () {
    return {
      settings: {
        centerMode: true,
        centerPadding: this.reviews.length > 3 ? "50px" : "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        swipe: this.reviews.length > 3 ? true : false,
        dots: this.reviews.length > 3 ? true : false,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerPadding: "0px",
              swipe: this.reviews.length > 1 ? true : false,
              dots: this.reviews.length > 1 ? true : false,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.review-slider {
  padding: 0 1rem;
}

.prev-btn,
.next-btn {
  top: 40%;
  z-index: 10;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.prev-btn {
  left: 5px;
}
.next-btn {
  right: 5px;
}

.review-card {
  background-color: var(--zimply-lighter-green);
  border-radius: 10px;
  padding: 0.5rem;
  height: 100%;

  .review-name {
    margin-bottom: 0;
  }
}

.nps-stars {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--star-yellow);
  font-size: 1.7rem;
  display: flex;
  justify-content: end;

  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
  }

  .b-icon {
    margin-inline: 5px;

    @media only screen and (max-width: 768px) {
      margin-inline: 2px;
    }
  }
}
</style>

<style lang="scss">
/* Slick carousel each slide same height */
.slick-track {
  display: flex !important;
}

.slick-track .slick-slide {
  display: flex !important;
  height: auto;

  & > div {
    width: 100%;
  }
}

.slick-dots {
  list-style: none;
  display: flex !important;
  justify-content: center;
  padding: 0;
  margin: 1rem 0;

  li {
    height: 4px;
    margin-left: 10px;
    margin-right: 10px;
    background: var(--zimply-brown);
    border-radius: 10px;

    button {
      color: transparent;
      background-color: transparent;
      border: none;
      width: 30px;
      height: 4px;
      padding: 0;
    }
  }

  .slick-active {
    background: var(--zimply-light-green);
  }
}
</style>
