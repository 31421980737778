<template>
  <b-row>
    <!-- <b-col cols="12">
      <h2>{{ title }}</h2>
    </b-col> -->
    <b-link
      target="_blank"
      :href="entry.url"
      v-for="(entry, index) in ingredients"
      class="ingredients-card col-12 col-md-4 col-xl-3"
      :key="index"
      :disabled="!entry.url"
    >
      <b-row>
        <b-col cols="3" md="12">
          <b-img
            :src="$ZNC_BACKEND_BASE + '/ui/img-essences/' + entry.image"
            class="mb-md-4 mb-2"
          />
        </b-col>
        <b-col cols="9" md="12" class="ingredients-title">
          <h3 class="m-0" :id="'essence-' + index">
            {{ entry.name_loc }}
          </h3>
          <div class="mb-md-4">&lbrack;{{ entry.name }}&rbrack;</div>
        </b-col>
        <b-col cols="12">
          {{ entry.description }}
        </b-col>
      </b-row>
    </b-link>
  </b-row>
</template>

<script>
export default {
  name: "InfoTabIngredients",
  props: ["title", "ingredients"],
};
</script>

<style lang="scss" scoped>
.ingredients-card {
  color: var(--zimply-black);
  border-radius: 10px;
  margin-block: 1rem;

  @media only screen and (min-width: 768px) {
    text-align: center;
  }

  img {
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 200px;
    border-radius: 50%;
    object-fit: cover;
  }

  .ingredients-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  &:hover {
    text-decoration: none;
    color: var(--zimply-black);
  }
}
</style>
