<template>
  <b-container class="facts" fluid>
    <b-row align-v="stretch">
      <b-col
        cols="6"
        xl="4"
        class="px-1 py-2"
        v-for="(symptom, index) in symptoms"
        :key="index"
      >
        <div class="fact">
          <b-img :src="symptom.img" class="icon" />
          <div class="f-p3 fact-text">{{ symptom.desc }}</div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailKeySymptoms",
  props: ["symptoms"],
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped>
.facts {
  .fact {
    background-color: var(--zimply-lighter-green);
    border-radius: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px;
    hyphens: auto;
    min-height: 55px;
    line-height: 1.2;

    div {
      padding-top: 2px;
    }

    .icon {
      height: 30px;
      width: 30px;
      margin-right: 10px;
      aspect-ratio: 1/1;
    }

    @media only screen and (min-width: 768px) {
      .fact-text {
        font-size: 1rem;
      }
    }
  }
}
</style>
