<template>
  <b-row>
    <b-col v-if="img" cols="12" md="9">
      <!-- <h2>{{ title }}</h2> -->
      <slot />
    </b-col>
    <b-col v-else cols="12">
      <!-- <h2>{{ title }}</h2> -->
      <slot />
    </b-col>
    <b-col v-if="img" cols="12" md="3">
      <b-img :src="require('@/assets/' + img + '')" class="tab-img" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InfoTabText",
  props: ["title", "img"],
};
</script>

<style lang="scss" scoped>
.tab-img {
  width: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    margin-top: 0.75rem;
  }
}
</style>
