<template>
  <div>
    <b-input-group>
      <b-form-input
        v-model.trim="code"
        @keydown.enter="applyVoucher()"
        @input="onInput()"
        :state="state"
        class="voucher"
        name="voucher"
        type="text"
        :placeholder="$t('voucherInput')"
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="outline-primary" @click="applyVoucher()">
          <b-spinner v-if="running" small></b-spinner>
          <BIconCaretRightFill v-if="!running" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <div v-if="state == false" class="error">
      {{ $t("errorVoucherInvalid") }}
    </div>
  </div>
</template>

<script>
import { BIconCaretRightFill } from "bootstrap-vue";

export default {
  name: "CheckoutVoucherInput",
  components: {
    BIconCaretRightFill,
  },
  i18nOptions: { keyPrefix: "checkout" },
  props: ["email"],
  data: function () {
    return {
      running: false,
      state: null,
      code: null,
    };
  },
  created: function () {
    this.$EventBus.$on("clear_voucher_input", this.clearVoucherInput);
  },
  methods: {
    applyVoucher: function () {
      if (!this.code) {
        this.state = false;
        return;
      }

      this.running = true;
      this.state = null;

      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/voucher", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: this.code,
          email: this.email,
          cart: this.$cart,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return null;
          }
        })
        .then((result) => {
          if (result) {
            this.$emit("set_voucher", result);
            this.state = true;
          } else {
            this.state = false;
          }
          this.running = false;
        });
    },
    clearVoucherInput: function () {
      this.code = null;
      this.state = null;
    },
    onInput: function () {
      this.$emit("input");
      this.state = null;
    },
  },
};
</script>

<style scoped>
input.voucher {
  max-width: 11.2rem;
}
</style>
