import { render, staticRenderFns } from "./ProductDetailAppointmenttBanner.vue?vue&type=template&id=6265c916&scoped=true"
import script from "./ProductDetailAppointmenttBanner.vue?vue&type=script&lang=js"
export * from "./ProductDetailAppointmenttBanner.vue?vue&type=script&lang=js"
import style0 from "./ProductDetailAppointmenttBanner.vue?vue&type=style&index=0&id=6265c916&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6265c916",
  null
  
)

export default component.exports