<template>
  <div class="start">
    <div class="header banner mb-5">
      <h1>{{ $t("start.headline") }}</h1>
    </div>
    <b-container fluid="xxl">
      <b-row class="mb-5 mb-md-0">
        <b-col cols="12" md="9" v-if="useSearch">
          <h3>
            {{ $t("start.searchTitle") }}
          </h3>
          <div class="position-relative">
            <b-form-textarea
              v-model.trim="$search.string"
              name="searchString"
              class="search-input f-h3 pt-2 pb-2 mt-2 mb-2"
              @keydown.enter.prevent="searchIndications()"
              :placeholder="$t('start.searchInput')"
              :rows="$isMobile ? 3 : 2"
            />
            <div class="btn-search-wrapper">
              <b-button variant="link" size="lg" @click="searchIndications()">
                <BIconSearch />
              </b-button>
            </div>
          </div>
          <div class="f-p4">
            <i18next :translation="$t('start.searchHint')">
              <template #privacyLink>
                <b-link
                  target="_blank"
                  tabindex="-1"
                  class="text-link"
                  :href="$t('checkout.privacyURL')"
                  >{{ $t("start.searchHintPrivacyLinkText") }}</b-link
                >
              </template>
            </i18next>
          </div>
          <div class="pt-4 error" v-if="noBusiness">
            {{ $t("start.noBusiness") }}
          </div>
          <div v-if="$search.result != null">
            <div
              v-if="
                $search.result.indications.length == 0 &&
                $search.result.stdProducts.length == 0 &&
                $search.result.mixtures.length == 0
              "
              class="pt-4"
            >
              <template v-if="$zmode == 'if-p'">
                {{ $t("start.noResult_partner") }}
              </template>
              <template v-else>
                {{ $t("start.noResult") }}
              </template>
              <b-container
                fluid
                xl="*"
                class="hp-termin"
                v-if="$zmode != 'if-p'"
              >
                <b-row no-gutters>
                  <b-col cols="5" lg="2">
                    <b-img
                      :alt="'Tobias Eisenkolb; ' + $t('start.practitionerInfo')"
                      src="@/assets/hp.webp"
                      class="hp"
                    />
                  </b-col>
                  <b-col cols="7" lg="3" class="text-left">
                    <div class="hp-caption">
                      <div class="font-weight-bold">Tobias Eisenkolb</div>
                      <div>{{ $t("start.practitionerInfo") }}</div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="7" class="button-col">
                    <b-button
                      v-if="liveChatAvailable"
                      variant="primary"
                      @click.prevent="openChat()"
                    >
                      <BIconChatDots />&nbsp;
                      {{ $t("start.buttonTermin", { context: "liveChat" }) }}
                    </b-button>
                    <b-button
                      v-else
                      variant="primary"
                      target="_blank"
                      href="https://calendly.com/tobias-58/30min"
                    >
                      <BIconCalendar3 />&nbsp;
                      {{ $t("start.buttonTermin", { context: "offline" }) }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <div v-else>
              <div
                class="search-result"
                v-if="$search.result.mixtures.length > 0"
              >
                <div class="pb-2">
                  {{ $t("start.searchResultM") }}
                </div>
                <b-button-group :vertical="true">
                  <b-button
                    v-for="(entry, index) in $search.result.mixtures"
                    :key="'m-button-' + index"
                    :id="'m-button-' + index"
                    variant="outline-dark"
                    :style="
                      'background-color: rgb(59, 64, 24, ' +
                      (parseInt(entry.probability) >= 0
                        ? entry.probability / 100
                        : 0.5) +
                      ');color: ' +
                      (parseInt(entry.probability) >= 70 ? 'white' : 'black')
                    "
                    size="lg"
                    :to="{
                      name: 'ProductView',
                      params: {
                        product_num: entry.mixture,
                      },
                    }"
                  >
                    {{ entry.mixture }}
                    <template v-if="parseInt(entry.probability) >= 0"
                      >({{ entry.probability }}%)
                    </template>
                  </b-button>
                </b-button-group>
              </div>
              <div
                class="search-result"
                v-if="$search.result.indications.length > 0"
              >
                <div class="pb-2">
                  {{
                    $t("start.searchResultI", {
                      count: $search.result.indications.length,
                    })
                  }}
                </div>
                <b-button-group :vertical="true">
                  <b-button
                    v-for="(entry, index) in $search.result.indications"
                    :key="'i-button-' + index"
                    :id="'i-button-' + index"
                    variant="outline-dark"
                    :style="
                      'background-color: rgb(59, 64, 24, ' +
                      (parseInt(entry.probability) >= 0
                        ? entry.probability / 100
                        : 0.5) +
                      ');color: ' +
                      (parseInt(entry.probability) >= 70 ? 'white' : 'black')
                    "
                    size="lg"
                    :to="{
                      name: entry.indication,
                      params: {
                        fromStart: true,
                      },
                    }"
                  >
                    {{ $t("indicationTitle", { context: entry.indication }) }}
                    <template v-if="parseInt(entry.probability) >= 0"
                      >({{ entry.probability }}%)
                    </template>
                  </b-button>
                </b-button-group>
                <b-tooltip
                  v-for="(entry, index) in $search.result.indications"
                  :key="'i-tooltip-' + index"
                  :target="'i-button-' + index"
                  triggers="hover"
                  placement="bottomright"
                  variant="secondary"
                >
                  {{ $t("indicationDesc", { context: entry.indication }) }}
                </b-tooltip>
              </div>
              <div
                class="search-result"
                v-if="$search.result.stdProducts.length > 0"
              >
                <div class="pb-2">
                  {{
                    $t("start.searchResultS", {
                      count: $search.result.stdProducts.length,
                    })
                  }}
                </div>
                <b-button-group :vertical="true">
                  <b-button
                    v-for="(entry, index) in $search.result.stdProducts"
                    :key="'s-button-' + index"
                    :id="'s-button-' + index"
                    variant="outline-dark"
                    :style="
                      'background-color: rgb(59, 64, 24, ' +
                      (parseInt(entry.probability) >= 0
                        ? entry.probability / 100
                        : 0.5) +
                      ');color: ' +
                      (parseInt(entry.probability) >= 70 ? 'white' : 'black')
                    "
                    size="lg"
                    :to="{
                      name: 'ProductView',
                      params: {
                        product_num: entry.product,
                      },
                    }"
                  >
                    {{ entry.product_name }}
                    <template v-if="parseInt(entry.probability) >= 0"
                      >({{ entry.probability }}%)
                    </template>
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>
          <div class="text-left pt-4">
            <i18next :translation="$t('start.switchToSelection')">
              <template #link>
                <b-link class="text-link" @click="useSearch = false">{{
                  $t("start.switchToSelectionLinkText")
                }}</b-link>
              </template>
            </i18next>
          </div>
        </b-col>
        <b-col cols="12" md="9" v-else>
          <div class="text-center pb-4">
            {{ $t("start.title") }}
          </div>
          <b-row>
            <b-col
              cols="12"
              md="6"
              xl="4"
              v-for="(entry, index) in indications"
              :key="index"
            >
              <b-button
                variant="outline-dark"
                class="btn-config"
                size="lg"
                :id="'button-' + index"
                :to="{ name: entry, params: { fromStart: true } }"
              >
                <div>
                  {{ $t("indicationTitle", { context: entry }) }}
                </div>
              </b-button>
              <b-tooltip
                :target="'button-' + index"
                triggers="hover"
                placement="bottomright"
                variant="secondary"
              >
                <span>{{ $t("indicationDesc", { context: entry }) }}</span>
              </b-tooltip>
            </b-col>
            <b-col cols="12" md="6" xl="4" v-if="$zmode != 'if-p'">
              <b-button
                variant="outline-dark"
                class="btn-config"
                size="lg"
                id="button-termin"
                target="_blank"
                href="https://calendly.com/tobias-58/30min"
              >
                <div>{{ $t("start.arrangeAppointmentButton") }}</div>
              </b-button>
              <b-tooltip
                target="button-termin"
                triggers="hover"
                placement="bottomright"
                variant="secondary"
              >
                {{ $t("start.arrangeAppointmentDesc") }}
              </b-tooltip>
            </b-col>
          </b-row>
          <i18next :translation="$t('start.switchToSearch')">
            <template #link>
              <b-link class="text-link" @click="useSearch = true">{{
                $t("start.switchToSearchLinkText")
              }}</b-link>
            </template>
          </i18next>
        </b-col>
        <b-col cols="12" md="3">
          <div class="sidebar">
            <b-img
              v-if="$pharmacy.available"
              :src="
                $ZNC_BACKEND_BASE + '/ui/img-pharmacy/' + $pharmacy.id + '.png'
              "
              fluid
              :alt="
                $pharmacy.contact.salutation +
                ' ' +
                $pharmacy.contact.title +
                ' ' +
                $pharmacy.contact.firstname +
                ' ' +
                $pharmacy.contact.lastname
              "
              class="w-100"
            ></b-img>
            <b-skeleton-img v-else aspect="6:5" />
            <div class="container">
              <div class="row align-items-center pt-2 px-2">
                <span v-if="$pharmacy.available" class="h4 title mb-0">
                  {{ $pharmacy.contact.title }}
                  {{ $pharmacy.contact.firstname }}
                  {{ $pharmacy.contact.lastname }}
                </span>
                <b-skeleton v-else class="h4 title mb-0 skeleton" width="75%" />
              </div>
              <div class="row align-items-center px-2">
                <span v-if="$pharmacy.available">
                  {{
                    $t("start.pharmacistSalutation", {
                      context: $pharmacy.contact.salutation,
                    })
                  }}
                  <template v-if="$pharmacy.contact.title2">
                    &amp;
                    {{
                      $t("start.pharmacistTitle2", {
                        context: $pharmacy.contact.title2,
                      })
                    }}
                  </template>
                </span>
                <b-skeleton v-else width="95%" />
              </div>
              <div class="row align-items-center pb-2 px-2">
                <span v-if="$pharmacy.available">
                  {{ $pharmacy.name }}
                </span>
                <b-skeleton v-else width="55%" />
              </div>
              <div class="row align-items-center p-2">
                <span class="h4 title mb-0"
                  >{{ this.formatNumber(this.$znc_stats.ts_stars)
                  }}<span class="h5 mb-0 title">/5</span></span
                >
                <span class="nps-stars">
                  <BIconStarFill scale="1.5" />
                  <BIconStarFill scale="1.5" />
                  <BIconStarFill scale="1.5" />
                  <BIconStarFill scale="1.5" />
                  <BIconStarFill scale="1.5" />
                </span>
              </div>
              <div class="row align-items-center p-2">
                <span class="h4 title pr-2 mb-0"
                  >{{ this.formatNumber(this.$znc_stats.configs, 0, 0) }}+</span
                >{{ $t("start.createdMixtures") }}
              </div>
              <div class="row align-items-center p-2">
                <span class="h4 title pr-2 mb-0">83%</span
                >{{ $t("start.satisfiedCustomers") }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid="fluid" class="banner ptb-60">
      <b-container fluid="xxl">
        <b-row class="align-items-center">
          <b-col cols="12" md="8" class="pb-0">
            <h2>{{ $t("start.giveASprayTitle") }}</h2>
            <div>
              {{ $t("start.giveASprayDesc") }}
            </div>
          </b-col>
          <b-col cols="12" md="4" class="pb-0 mt-3 mt-md-0">
            <b-button
              variant="green"
              class="lh-1"
              size="lg"
              id="button-gift-voucher"
              to="geschenkgutschein"
            >
              {{ $t("start.giveASprayButton") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-overlay :show="running" rounded="sm" no-wrap>
      <template #overlay>
        <div class="text-center">
          <b-spinner class="wait-spinner"></b-spinner>
          <div class="f-p1">{{ $t("start.searchWaitText") }}</div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BIconStarFill,
  BIconSearch,
  BIconChatDots,
  BIconCalendar3,
} from "bootstrap-vue";

export default {
  name: "StartView",
  components: {
    BIconStarFill,
    BIconSearch,
    BIconChatDots,
    BIconCalendar3,
  },
  data: function () {
    return {
      indications: [
        "Schlafen",
        "Stress",
        "Kopf",
        "Motivation",
        "Sod",
        "Immun",
        "Heu",
        "Wechsel",
        "Periode",
        "Akne",
        "Herpes",
      ],
      useSearch: true,
      running: false,
      noBusiness: false,
    };
  },
  mounted: function () {
    if (this.$route.params.performSearch) {
      this.$search.string = this.$route.params.performSearch;
      this.searchIndications();
    }
  },
  computed: {
    liveChatAvailable: function () {
      const date = new Date();
      const hour = date.getHours();
      const day = date.getDay();
      return (
        this.$chat.available && day >= 1 && day <= 5 && hour >= 9 && hour < 17
      );
    },
  },
  methods: {
    searchIndications: function () {
      if (
        this.$search.string == null ||
        this.$search.string.trim().length == 0
      ) {
        return;
      }

      this.running = true;
      this.noBusiness = false;
      this.$search.result = null;
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-start",
        schritt: "indikationssuche",
        teilschritt: this.$search.string,
      });

      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/search-indications", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search: this.$search.string,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            this.$search.result = result;
            this.running = false;
          } else if (status == 404) {
            this.noBusiness = true;
            this.running = false;
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
            } else {
              this.noBusiness = true;
              this.running = false;
            }
          }
        });
    },
    openChat: function () {
      this.$EventBus.$emit("openChat");
    },
  },
};
</script>

<style lang="scss">
.start {
  .skeleton {
    height: unset;
  }
  .search-input {
    background-color: var(--zimply-beige);
    padding-right: 3rem;
  }
  @media only screen and (max-width: 576px) {
    .search-input {
      padding-right: 2rem;
    }
  }
  .btn-search-wrapper {
    position: absolute;
    right: 0rem;
    top: 0em;
    bottom: 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .search-result {
    margin-top: 1rem;
    text-align: center;
    .btn {
      min-width: 300px;
      margin-bottom: 0.3rem;
    }
  }
  .hp-termin {
    background-color: var(--zimply-brown);
    color: var(--zimply-black);
    border-radius: 10px;
    padding: 1rem;

    img.hp {
      height: 120px;
    }

    .button-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: unset;
    }
    @media only screen and (max-width: 992px) {
      .button-col {
        padding-top: 1rem;
      }
    }
  }
}
</style>
<style>
.start .col-12 {
  padding-bottom: 10px;
}
.start .btn {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 576px) {
  .start .col-12 {
    padding-bottom: 30px;
  }
  .start .btn {
    line-height: 2.5;
  }
}
.start .btn img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.start .btn div {
  position: relative;
}

.nps-stars {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  color: var(--star-yellow);
}
.nps-stars .b-icon {
  margin-left: 7px;
  margin-right: 7px;
}
</style>
