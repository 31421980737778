<template>
  <b-row>
    <!-- <h2>{{ title }}</h2> -->
    <b-col cols="12" md="6">
      <b-card
        no-body
        class="mb-1 accordion-card"
        v-for="(tab, i) in oddAccordion"
        :key="i * 2"
      >
        <b-card-header
          header-tag="header"
          class="p-1 accordion-header"
          role="tab"
        >
          <b-button
            block
            v-b-toggle="'accordion-info-tab-' + i * 2"
            class="accordion-header-trigger f-h3"
          >
            {{ tab.heading }}
            <BIconDash class="when-open icon" />
            <BIconChevronDown class="when-closed icon" />
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-info-tab-${i * 2}`"
          accordion="info-tab-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text class="f-p3">
              <i18next :translation="tab.text">
                <template #lineBreak>
                  <br />
                </template>
              </i18next>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-col>
    <b-col cols="12" md="6">
      <b-card
        no-body
        class="mb-1 accordion-card"
        v-for="(tab, i) in evenAccordion"
        :key="i * 2 - 1"
      >
        <b-card-header
          header-tag="header"
          class="p-1 accordion-header"
          role="tab"
        >
          <b-button
            block
            v-b-toggle="'accordion-info-tab-' + (i * 2 - 1)"
            class="accordion-header-trigger f-h3"
          >
            {{ tab.heading }}
            <BIconDash class="when-open icon" />
            <BIconChevronDown class="when-closed icon" />
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-info-tab-${i * 2 - 1}`"
          accordion="info-tab-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text class="f-p3">
              <i18next :translation="tab.text">
                <template #lineBreak>
                  <br />
                </template>
              </i18next>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BIconChevronDown, BIconDash } from "bootstrap-vue";

export default {
  name: "InfoTabAccordion",
  props: ["title", "accordion"],
  components: {
    BIconChevronDown,
    BIconDash,
  },
  computed: {
    oddAccordion: function () {
      return this.accordion.filter((element, index) => index % 2 === 0);
    },
    evenAccordion: function () {
      return this.accordion.filter((element, index) => index % 2 != 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-card {
  border-radius: 10px;
}
.accordion-header {
  background-color: transparent;
  border: none;
  padding: 0;

  .accordion-header-trigger {
    background-color: transparent;
    border: none;
    color: var(--zimply-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    text-transform: none;
    text-align: left;

    svg {
      font-size: 1.5rem;
    }

    &:hover,
    &:active {
      background-color: transparent;
      border: none;
      color: var(--zimply-black);
      box-shadow: none !important;
    }
  }
}

.icon {
  margin-left: 5px;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
